import { useIntl } from 'react-intl';

export default function Home() {
  const intl = useIntl();

  return (
    <div>
      {intl.formatMessage({
        defaultMessage: 'Hello Sammy',
        id: 'kmuZUz',
      })}
    </div>
  );
}
